import React, { useState, useEffect } from "react";

const UserInfo = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    phone:"",
    email: "",
    
  });


  const handleChange = (e) => {
    
    // const values = e.phone.target.value.replace(/[^0-9]/g, "");
    // setPhone(e.target.value)
    const { name, value } = e.target;
    setFormData((prevData) => ({

      ...prevData,
      [name]: value,
    }));
    // props.onUserInfo(formData)

  };

  useEffect(() => {
    props.onUserInfo(formData);
  }, [formData, props]);


  
 
  return (
    <div className="p-6">
      <h6 className="mb-1 text-xl font-bold text-center sm:text-2xl">
      Your estimate is almost Ready
            </h6>
      <p className="mb-3 text-sm text-center">
      Please furnish your particulars, and we'll get back to you within 48 hours.      </p>

      <form action="/mail.php" method="POST" className="mt-2">
        <div className="mt-4">
          <label className="block mb-1 ">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-2 text-base border rounded-md"
            placeholder="Enter Name"
          />
        </div>
        <div className="grid grid-cols-1 gap-4 mt-4 lg:grid-cols-2">
          <div>
            <label className="block mb-1">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 text-base border rounded-md"
              placeholder="Enter Email"
            />
          </div>
          <div>
            <label className="block mb-1 ">Phone</label>
            <input
              type="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-2 text-base border rounded-md"
              placeholder="Enter Phone"
            />
          </div>
        </div>
      </form>
      <small>
        By submitting this form, you agree to the privacy policy & terms and
        conditions This site is protected by reCAPTCHA and the Google Privacy
        Policy and Terms of Service apply.
      </small>
    </div>
  );
};

export default UserInfo;
