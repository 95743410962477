import React, { useState, useEffect } from "react";
import Checkbox from "./Checkbox";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";

// import { makeStyles } from '@mui/material/styles';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& .MuiTextField-root": {
//       margin: theme.spacing(1),
//       width: "25ch"
//     }
//   }
// }));

export const Package = (props) => {
  const [selectedPlys, setSelectedPlys] = useState([]);
  const [categoryWardrobe, setCategoryWardrobe] = useState([]);


  useEffect(() => {
    const activeItems = props?.PlyInfo.filter((item) => item.status === "Active");
    const filteredDataPly = activeItems?.filter(item => item.category === props?.cupboard);
    setCategoryWardrobe(filteredDataPly)


   

    

  }, [props.PlyInfo]);


  const selectedPlyHandler = (value) => {
    setSelectedPlys(value);
    props.onPassPlyData(value);
  };

  return (
    <div>
      <div className=" cards-body">
        <h6 className="pb-0 mb-1 text-xl font-bold text-center sm:text-2xl">
          {props?.cupboard}
        </h6>
        <p className="mb-3 text-sm text-center">
        Select the Ply Type.
        </p>
        <div>
            <Checkbox
              PlyData={categoryWardrobe}
              onSelectedPly={selectedPlyHandler}
              InputValue={props.InputValue}
              frontEnd={props.frontEnd}
            />
        </div>
      </div>
    </div>
  );
};
