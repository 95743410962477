import React, { useState, useEffect } from "react";
import "./components.css";
import phoneIcon from "../img/phone.png";

export const CardForm = () => {
  const [answer, setAnswer] = useState("yes");
  const [nearingYes, setNearingYes] = useState();
  const [nearingNo, setNearingNo] = useState();

  const handleRadioChange = (e) => {
    setAnswer(e.target.value);
  };
  const handleCurrentYes = (e) => {
    setNearingYes(e.target.value);
  };
  const handleCurrentNo = (e) => {
    setNearingNo(e.target.value);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <div>
      <div className="grid lg:grid-cols-2 contact-body">
        <div className="flex flex-col justify-center">
          <div className="form-body">
            <h4 className="sub-head">
              Realize your dream interiors with just a click.
            </h4>
            <p>
              Transform your home into your dream space. Consult with our
              experts today.
            </p>
            <div className="flex">
              <a className="mt-6 btn btn-border " href="tel:8891639345">
                <span className="flex items-center gap-2">
                  <img src={phoneIcon} alt="" className="w-auto " />{" "}
                  <span>+91 8891639345 </span>
                </span>
              </a>
            </div>
          </div>
        </div>

        <div className="card-contact ">
          <div className="inline-block">
            <h4>Get in touch with us</h4>
          </div>
          <div>
          <form action="/mail.php" method="POST" className="mt-2">
              <div className="mt-4">
                <label className="block mb-1 ">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md"
                  placeholder="Enter Name"
                />
              </div>
              <div className="grid grid-cols-1 gap-4 mt-4 lg:grid-cols-2">
                <div>
                  <label className="block mb-1">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md"
                    placeholder="Enter Email"
                  />
                </div>
                <div>
                  <label className="block mb-1 ">Phone</label>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md"
                    placeholder="Enter Phone"
                  />
                </div>
              </div>

              <div className="mt-4">
                <label
                  for="Current"
                  className="block mb-2 text-sm font-medium text-gray-9"
                >
                  Choose your product

                </label>
                <select
                name="product"
                  id="product"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                >
                     <option value="Modular Interiors ">Modular Interiors </option>
                  <option value="Full Home Interiors">Full Home Interiors</option>
                  <option value="Luxury Interiors">Luxury Interiors</option>
                  <option value="Renovations">Renovations</option>
                  <option value="Commercial Interiors">Commercial Interiors</option>
                </select>
              </div>

              <div className="mt-4">
                <label
                  for="Current"
                  className="block mb-2 text-sm font-medium text-gray-9"
                >
                  Home ready for interior design now?
                </label>
                <div className="flex">
                  <div className="flex items-center me-4">
                    <input
                      id="radio"
                      type="radio"
                      value="yes"
                      name="now"
                      checked={answer === "yes"}
                      onChange={handleRadioChange}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 "
                    />
                    <label
                      for="inline-radio"
                      className="text-sm font-medium text-gray-900 ms-2 "
                    >
                      Yes
                    </label>
                  </div>
                  <div className="flex items-center me-4">
                    <input
                      id="radio2"
                      type="radio"
                      value="no"
                      name="now"
                      checked={answer === "no"}
                      onChange={handleRadioChange}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                    />
                    <label
                      for="inline-2-radio"
                      className="text-sm font-medium text-gray-900 ms-2"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              {answer === "yes" && (
                <div>
                  <div className="mt-4">
                    <label
                      for="Current-yes"
                      className="block mb-2 text-sm font-medium text-gray-9"
                    >
                      Current stage of construction ?
                    </label>
                    <select
                    name="stage"
                      id="Current-yes"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                      onChange={handleCurrentYes}
                    >
                      <option value="">Select</option>
                      <option value="nearing-completion-yes">
                        Nearing Completion
                      </option>
                      <option value="completed-yes">Completed</option>
                    </select>
                  </div>
                  {nearingYes !== "" && (
                    <>
                      {nearingYes === "nearing-completion-yes" && (
                        <div className="mt-4">
                          <label
                            for="construction-yes"
                            className="block mb-2 text-sm font-medium text-gray-9"
                          >
                            Expected construction completion?
                          </label>
                          <select
                          name="expected_cons"
                            id="construction-yes"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                            onChange={handleCurrentNo}
                          >
                            <option value="">Select</option>

                            <option value="Immediately">Immediately</option>
                            <option value="Within 1 Month">Within 1 Month</option>
                          </select>
                        </div>
                      )}
                      {nearingYes === "completed-yes" && (
                        <div className="mt-4">
                          <label
                            for="construction-completed-yes"
                            className="block mb-2 text-sm font-medium text-gray-9"
                          >
                            Expected construction completion?
                          </label>
                          <select
                          name="cons_compl"
                            id="construction-completed-yes"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                          >
                            <option value="">Select</option>

                            <option value="Immediately">Immediately</option>
                          </select>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
              {answer === "no" && (
                <>
                  <div className="mt-4">
                    <label
                      for="Current-no"
                      className="block mb-2 text-sm font-medium text-gray-9"
                    >
                      Current stage of construction ?
                    </label>
                    <select
                    name="current_stage"
                      id="Current-no"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                      onChange={handleCurrentNo}
                    >
                      <option value="">Select</option>
                      <option value="planning">Planning</option>
                      <option value="custruction-ongoing">
                        Custruction Ongoing
                      </option>
                    </select>
                  </div>
                  {nearingNo !== "" && (
                    <>
                      {nearingNo === "planning" && (
                        <div className="mt-4">
                          <label
                            for="planning-no"
                            className="block mb-2 text-sm font-medium text-gray-9"
                          >
                            Expected construction completion?
                          </label>
                          <select
                          name="cons_compl1"
                            id="planning-no"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                          >
                            <option value="">Select</option>
                            <option value="Within 6 - 12 Months">
                              Within 6 - 12 Months
                            </option>
                            <option value="After 1 Year">After 1 Year</option>
                          </select>
                        </div>
                      )}
                      {nearingNo === "custruction-ongoing" && (
                        <div className="mt-4">
                          <label
                            for="construction-no"
                            className="block mb-2 text-sm font-medium text-gray-9"
                          >
                            Expected construction completion?
                          </label>
                          <select
                          name="expected_cons1"
                            id="construction-no"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                          >
                            <option value="">Select</option>
                            <option value="Within 1 - 3 Months">
                              Within 1 - 3 Months
                            </option>
                            <option value="A Within 3 - 6 Months">
                              Within 3 - 6 Months
                            </option>
                          </select>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              <div className="mt-4">
                <label className="block mb-1 ">Message</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md"
                  placeholder="Enter Message"
                ></textarea>
              </div>
              <div className="mt-6 ">
                <button className="w-full btn btn-primary" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        <div></div>
      </div>
    </div>
  );
};
