import { useState, useEffect } from "react";
import logo from "../img/logo.png";
import { NavLink } from "react-router-dom";
import "./navBar.css";
import { Navbar } from "flowbite-react";
import { Button } from "flowbite-react";
import { Dropdown } from "flowbite-react";
import ModalBook from "./BookModal";
export const NavBar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [smHide, setSmHide] = useState(true);
  const [showBookDemo, setShowBookDemo] = useState(false);

  const [isOpens, setIsOpens] = useState(false);

  const toggleAccordion = () => {
    setIsOpens(!isOpens);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 994) {
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = () => {
    setToggleMenu(!toggleMenu);
    setSmHide(!smHide);
  };
  const closeMenu = () => {
    setToggleMenu(!toggleMenu);
    if (window.innerWidth <= 1020) {
      setSmHide(!smHide);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const BookDemoHandler = () => {
    setShowBookDemo(true);
  };
  return (
    <div>
      <div
        className={
          smHide
            ? "nav px-5 xl:px-20  lg:px-2 py-4 text-white flex  justify-between items-center"
            : "nav nav-sm"
        }
      >
        {smHide ? (
          <div>
            <NavLink to="/">
              <img src={logo} alt="log" className="logo" />
            </NavLink>
          </div>
        ) : null}
        <div
          className={
            toggleMenu
              ? "lg:flex lg:pt-0  w-full md:w-auto pt-16"
              : "hidden md:flex"
          }
          id="menu"
        >
          <div className="cursor-pointer sm-menu-logo lg:hidden lg-none">
            <NavLink to="/">
              <img src={logo} alt="log" className=" menu-logo" />
            </NavLink>
          </div>
          <ul>
            <li className="py-3 pl-6 border-b cursor-pointer sm:pl-3 sm:pr-3 md:inline-block hover:text-gray-400 md:border-none active">
              <NavLink to="/" onClick={closeMenu}>
                Home
              </NavLink>
            </li>
            <li className="relative py-3 pl-6 border-b cursor-pointer sm:pl-3 sm:pr-3 dropdown md:inline-block hover:text-gray-400 md:border-none">
              <div className="hidden md:flex">
                <Dropdown label="Our Products" dismissOnClick={false}>
                  <Dropdown.Item>
                    <NavLink
                      to="/OurProducts"
                      onClick={closeMenu}
                      className="text-left "
                    >
                      Modular Interiors
                    </NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <NavLink
                      to="/HomeInteriors"
                      onClick={closeMenu}
                      className="text-left "
                    >
                      Full Home Interiors
                    </NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <NavLink
                      to="/LuxuryInteriors"
                      onClick={closeMenu}
                      className="text-left "
                    >
                      Luxury Interiors
                    </NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <NavLink
                      to="/Renovations"
                      onClick={closeMenu}
                      className="text-left "
                    >
                      Renovations
                    </NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <NavLink
                      to="/Commercial"
                      onClick={closeMenu}
                      className="text-left "
                    >
                      Commercial Interiors
                    </NavLink>
                  </Dropdown.Item>
                </Dropdown>
              </div>
              <div className="w-full lg:hidden">
                <button
                  className="w-full text-left hover:bg-gray-300"
                  onClick={toggleAccordion}
                >
                  <div className="flex items-center gap-2">
                    <span>Our Products</span>
                    <svg
                      className={`w-6 h-6 ${
                        isOpens ? "transform rotate-180" : ""
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </button>
                {isOpens && (
                  <div className="pt-4 pb-1 text-center rounded-t-sm ">
                    <ul className="acc-nav">
                      <li className="py-3 ">
                        <NavLink
                          to="/OurProducts"
                          onClick={closeMenu}
                          className="text-xs text-left "
                        >
                          Modular Interiors
                        </NavLink>
                      </li>
                      <li className="py-3">
                        <NavLink
                          to="/HomeInteriors"
                          onClick={closeMenu}
                          className="text-left "
                        >
                          Full Home Interiors
                        </NavLink>
                      </li>
                      <li className="py-3 ">
                        <NavLink
                          to="/LuxuryInteriors"
                          onClick={closeMenu}
                          className="text-left "
                        >
                          Luxury Interiors
                        </NavLink>
                      </li>
                      <li className="py-3">
                        <NavLink
                          to="/Renovations"
                          onClick={closeMenu}
                          className="text-left "
                        >
                          Renovations
                        </NavLink>
                      </li>
                      <li className="py-3">
                        <NavLink
                          to="/Commercial"
                          onClick={closeMenu}
                          className="text-left "
                        >
                          Commercial Interiors
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </li>
            <li className="py-3 pl-6 border-b cursor-pointer sm:pl-3 sm:pr-3 md:inline-block hover:text-gray-400 md:border-none">
              <NavLink to="/PriceCalculator" onClick={closeMenu}>
                Price Calculator
              </NavLink>
            </li>
            <li className="py-3 pl-6 border-b cursor-pointer sm:pl-3 sm:pr-3 md:inline-block hover:text-gray-400 md:border-none">
              <NavLink to="/OurWorks" onClick={closeMenu}>
                Our Works
              </NavLink>
            </li>
            <li className="py-3 pl-6 border-b cursor-pointer sm:pl-3 sm:pr-3 md:inline-block hover:text-gray-400 md:border-none">
              <NavLink to="/AboutUs" onClick={closeMenu}>
                About Us
              </NavLink>
            </li>
            <li className="py-3 pl-6 border-b cursor-pointer sm:pl-3 sm:pr-3 md:inline-block hover:text-gray-400 md:border-none">
              <NavLink to="/Contact" onClick={closeMenu}>
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
        <div>
          <button onClick={BookDemoHandler} className="btn hide-sm btn-border ">
            Get Free Quote
          </button>
        </div>
        <div className="cursor-pointer lg:hidden nav-menu lg-none">
          <div>
            <input
              className="hidden menu-btn"
              type="checkbox"
              id="menu-btn"
              checked={toggleMenu}
              onChange={() => {}}
            />
            <label
              onClick={handleToggle}
              className="relative block px-2 py-4 cursor-pointer select-none menu-icon lg:hidden"
              for="menu-btn"
            >
              <span className="relative flex items-center navicon bg-white-darkest"></span>
            </label>
          </div>
        </div>
      </div>
      <ModalBook
        isVisible={showBookDemo}
        onClose={() => setShowBookDemo(false)}
      />
    </div>
  );
};
