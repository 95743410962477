import React, { useState, useEffect } from 'react';

export const FirstAccordion = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (index) => {
        setActiveIndex(activeIndex === index ? -1 : index);
    };

    return (
        <div>

            <div className="accordion-fq ">
                {props.item.map((itemAcc, index) => (
                    <div key={index} className="accordion-item">
                        <div
                            className={`accordion-header-fq flex justify-between ${activeIndex === index ? 'accordion-active' : ''}`}
                            onClick={() => handleClick(index)}
                        >
                            <h3 className="text-lg font-bold">{itemAcc.title}</h3>

                            <span className={`ml-2 transition-transform duration-200 transform color-icon `}>
                                {activeIndex === index ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                                    </svg>:<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                                
                                }
                            </span>
                        </div>
                        {activeIndex === index && (
                            <div className="accordion-content"><p>{itemAcc.content}</p></div>
                        )}
                    </div>
                ))}
            </div>



        </div>
    )
}
