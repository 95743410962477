import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./pages.css";
import { GallerySrc } from "../components/gallery";
import { AllGallery } from "../components/AllGallery";
import { Footer } from "../components/Footer";
import { Accordion } from "../components/Acoordin";
import Whatsapp from "../img/whatsapp.png";
import tenYr from "../img/6yrs.png";

const ImgPath = process.env.REACT_APP_IMg_URL;
const DiscountPath = process.env.REACT_APP_API_DISCOUN;

const FAQ = [
  {
    title: "How do I get started with your interior design services?",
    content:
      "Just give us a call to schedule a consultation where we can discuss your design goals and preferences.",
  },
  {
    title:
      "What is the typical timeline for completing an interior design project?",
    content:
    "There’s no specific timeline for completing a project. It depends upon the requirements of the client and the availability of materials",
  },
  {
    title: "Can I incorporate my existing furniture and decor into the design?",
    content:
    "Of Course, you can integrate your existing furniture and décor into the design. It will be a good blend of old and new",
  },
  {
    title: "Can you renovate my existing home?",
    content:
      "Yes. Your existing home can be renovated and redesigned according to your taste.",
  },
  {
    title: "Can I suggest changes in the design after the order is confirmed?",
    content:
      "Of Course. You have the freedom to suggest alternations even after the design is confirmed, but once the final product is complete it can be impossible to make changes.",
  },
  {
    title: "Who will do the installation for my home? ",
    content:
    "Your home will revamped by a talented team of professional architects and    interior designers. We’ll leave no stone unturned. "
   },
   {
    title: "How much is the Warranty Period?",
    content:
    "We are a step ahead when it comes to Warranty. Our period is 6 years!"
  },
  {
   title: "What kinds of materials do you use?",
   content:
   "We use Wood-Plastic Composites (WPC), Wood Ply, and Marine Ply for our products"
  },
  {
    title: "How much does it cost for a whole project?",
    content:
    "The overall project cost depends upon the materials used and the finalized design  details. Our designers will be able to provide the exact and every stage of the project"
   },
   {
    title: "Do you accept payments in EMI?",
    content:
    "Once the project is finalized, we will provide our payment schedule at the beginning of the project, then you can facilitate payments by credit/debit cards or EMI if it’s available at your bank."
   },
];

export const OurWorks = () => {
  const DivRef = useRef(null);
  const [discountData, setDiscountData] = useState([])

useEffect(() => {
  fetchDiscount();
}, []);
const fetchDiscount = async () => {
  try {
    const response = await axios.get(DiscountPath);
    const data = response?.data;
    setDiscountData(data);

  } catch (error) {
  } finally {
  }
};

  useEffect(() => {
    if (DivRef.current) {
      DivRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  });

  return (
    <div className="our-works pages" ref={DivRef}>
      <div className=" modular">
        <div className="sm-banner">
          <h1> Transforming homes with timelessness.</h1>
        </div>
      </div>
      <a
          className="Whats"
          href="https://wa.me/8891639345"
          target="_blank"
          tabindex="0"
        >
          <img className="" src={Whatsapp} alt="" />
        </a>
        <img className="dis" src={ImgPath + discountData[0]?.image} alt="" />
        <img className="yer" src={tenYr} alt="" />
      <div className="section">
        <div className="pb-5 title-section">
    
          <h4 className="sub-head">
          Where Styles meets Luxury and Comfort.
          </h4>
          <p>
          Enhance and elevate your home with unique design concepts customized just for you.
          </p>
        </div>
        <AllGallery />
      </div>
      <section className="px-4 section ideas lg:px-20">
        <h6 className="text-center sub-head ">
          Frequently Asked Questions (FAQs)
        </h6>
        <div className="grid grid-cols-1 gap-10">
          <Accordion item={FAQ}></Accordion>
        </div>
      </section>

      <Footer></Footer>
    </div>
  );
};
