import React, { useState, useEffect } from "react";
import axios from "axios";

import stepper from "../../img/steper.png";

// import { makeStyles } from '@mui/material/styles';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& .MuiTextField-root": {
//       margin: theme.spacing(1),
//       width: "25ch"
//     }
//   }
// }));

const CatgoryPath = process.env.REACT_APP_API_CATEGORY;
const FrontEndPath = process.env.REACT_APP_API_FRONT_END;

export default function InstituteDetails(props) {
  const [sq, setSq] = useState(props.InputValue);
  const [catgoryoptions, setCatgoryOptions] = useState([]);
  const [fontEndoptions, setFontEndOptions] = useState([]);

  const [catgoryData, setCatgoryData] = useState([]);
  const [fontEndData, setFontEndData] = useState([]);

 

  const handleChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, "");

    setSq(value);
    props.onInputChange(value);
  };

  useEffect(() => {
    fetchData();
    axios
      .get(FrontEndPath)
      .then((response) => {
        setFontEndOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data from API ", error);
      });
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(CatgoryPath);
      const data = response?.data;
      setCatgoryOptions(data);
    } catch (error) {
    } finally {
    }
  };
  
  const handleChangecupboard = (event) => {
    setCatgoryData(event.target.value)
    
    props.oncupboardChange(event.target.value);
    const selectedCatgory = catgoryoptions.filter(item => item.category === event.target.value) 

    props.onSelectedAllData(selectedCatgory)
    console.log(" props.oncupboardChange", selectedCatgory)
  };
  const handleFrontEnd = (event) => {
    setFontEndData(event.target.value)
    props.onFrontEndChange(event.target.value);
  };
  // useEffect(() => {
  //   props.oncupboardChange(cupboardOption);
  // }, []);

  //   const classes = useStyles();
  return (
    <div className="cards-body ">
      <div className="flex flex-col gap-4">
        <img className="w-32 m-auto sm:w-16" src={stepper} alt="" />
        <div>
          <h6 className="mb-1 text-xl font-bold text-center sm:text-2xl">
            Let's start to find a budget.
          </h6>
          <div className="m-auto mt-3 sm:w-6/12 border-2 p-2 bg-white rounded-lg drop-shadow-md  border-[#751757]">
            <label className="mb-4 text-sm text-center" htmlFor="input">
              Choose from options   <span class="text-red-600	">*</span>

            </label>
            <div className="mt-1">
              <select
                id="cupboard"
                className="w-full p-2 text-base border rounded-md bg-[#ededed]"
                value={catgoryData}
                onChange={handleChangecupboard}
              >
                <option value="">Select an option</option>
                {catgoryoptions.map((option) => (
                  <option key={option.id} value={option.category}>
                    {option.category}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="m-auto mt-3 sm:w-6/12 border-2 p-2 bg-white rounded-lg drop-shadow-md  border-[#751757]">
            <label className="mb-4 text-sm text-center" htmlFor="input">
              Fill in the exact sq. ft of your space.
            </label> <span class="text-red-600	">*</span>
            <div className="mt-1">
              <input
                type="text"
                name="name"
                value={sq}
                onChange={handleChange}
                className="w-full p-2 text-base border rounded-md bg-[#ededed]"
                placeholder="Enter sq.pt"
              />
            </div>
          </div>
          <div className="m-auto mt-3 sm:w-6/12 border-2 p-2 bg-white rounded-lg drop-shadow-md  border-[#751757]	">
            <label className="mb-4 text-sm text-center" htmlFor="input"> 
              Choose Finishing Product </label> <span class="text-red-600	">*</span>
            <div className="mt-1">
              <select
                id="cupboard"
                className="w-full p-2 text-base border rounded-md bg-[#ededed]"
                value={fontEndData}
                onChange={handleFrontEnd}
                required
              >
                <option value="">Select an option</option>
                {fontEndoptions.map((option) => (
                  <option key={option.id} value={option.price}>
                    {option.title}
                  </option>
                ))}
              </select>
            </div>
            
          </div>
          <div className="m-auto mt-3 sm:w-6/12">
            <span class="text-red-600	">*</span>  <small>Mandatory input</small>
          </div>
          
        </div>
      </div>
    </div>
  );
}
