import { useState, useEffect } from "react";
import axios from "axios";
import "./pages.css";
import Slider from "react-slick";
import Whatsapp from "../img/whatsapp.png";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Footer } from "../components/Footer";
import { Button } from "flowbite-react";
import { Carousel } from "flowbite-react";
import ProductCard from "../components/ProductCard";
import "@fortawesome/fontawesome-free/css/all.css";

import iconData from "../icon/bhk/1.png";
import iconKitchen from "../icon/bhk/2.png";
import iconDinning from "../icon/bhk/3.png";
import iconShose from "../icon/bhk/4.png";

import idea from "../img/idea.png";

import Modular from "../img/interior/1.png";

import Year from "../img/whychoose/Warranty.png";
import Citie from "../img/whychoose/Cities.png";
import Emi from "../img/whychoose/EasyEMIs.png";
import Checks from "../img/whychoose/Checks.png";
import home from "../img/whychoose/Homes.png";
import Guarantee from "../img/whychoose/Guarantee.png";
import vsOne from "../img/idea-1.png";
import vsTwo from "../img/idea-2.png";
import tenYr from "../img/6yrs.png";

import { Accordion } from "../components/Acoordin";
import { FirstAccordion } from "../components/FirstAcoordin";
import { GallerySrc } from "../components/gallery";
import ModalBook from "../components/BookModal";
import { NavLink } from "react-router-dom";

// const CustomFlowbiteTheme = {
//   scrollContainer: {
//     base: "flex h-full span-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth",
//     // snap: "span-x",
//   },
// };
const steps = [{ title: "Step 1" }, { title: "Step 2" }, { title: "Step 3" }];

const BhkPath = process.env.REACT_APP_API_BHK;
const ImgPath = process.env.REACT_APP_IMg_URL;
const DiscountPath = process.env.REACT_APP_API_DISCOUN;






const FAQ = [
  {
    title: "How do I get started with your interior design services?",
    content:
    "Just give us a call to schedule a consultation where we can discuss your design goals and preferences.",
  },
  {
    title:
      "What is the typical timeline for completing an interior design project?",
    content:
    "There’s no specific timeline for completing a project. It depends upon the requirements of the client and the availability of materials",
  },
  {
    title: "Can I incorporate my existing furniture and decor into the design?",
    content:
    "Of Course, you can integrate your existing furniture and décor into the design. It will be a good blend of old and new",
  },
  {
    title: "Can you renovate my existing home?",
    content:
      "Yes. Your existing home can be renovated and redesigned according to your taste.",
  },
  {
    title: "Can I suggest changes in the design after the order is confirmed?",
    content:
      "Of Course. You have the freedom to suggest alternations even after the design is confirmed, but once the final product is complete it can be impossible to make changes.",
  },
  {
    title: "Who will do the installation for my home? ",
    content:
    "Your home will revamped by a talented team of professional architects and    interior designers. We’ll leave no stone unturned. "
   },
   {
    title: "How much is the Warranty Period?",
    content:
      "We are a step ahead when it comes to Warranty. Our period is 6 years!"
  },
  {
   title: "What kinds of materials do you use?",
   content:
   "We use Wood-Plastic Composites (WPC), Wood Ply, and Marine Ply for our products"
  },
  {
    title: "How much does it cost for a whole project?",
    content:
    "The overall project cost depends upon the materials used and the finalized design  details. Our designers will be able to provide the exact and every stage of the project"
   },
   {
    title: "Do you accept payments in EMI?",
    content:
    "Once the project is finalized, we will provide our payment schedule at the beginning of the project, then you can facilitate payments by credit/debit cards or EMI if it’s available at your bank."
   },
];

const Vs = [
  {
    title: "Modern",
    content:
      "Modern design, within the realm of design, characterizes homes featuring clean aesthetics, minimalistic color palettes, and the incorporation of materials such as metal, glass, and steel, often complemented by sleek, uncluttered lines.",
  },
  {
    title: "Contemporary",
    content:
      "Modern design, within the realm of design, characterizes homes featuring clean aesthetics, minimalistic color palettes, and the incorporation of materials such as metal, glass, and steel, often complemented by sleek, uncluttered lines.",
  },
  {
    title: "Minimalis",
    content:
      "Modern design, within the realm of design, characterizes homes featuring clean aesthetics, minimalistic color palettes, and the incorporation of materials such as metal, glass, and steel, often complemented by sleek, uncluttered lines.",
  },
  {
    title: "Industrial",
    content:
      "Modern design, within the realm of design, characterizes homes featuring clean aesthetics, minimalistic color palettes, and the incorporation of materials such as metal, glass, and steel, often complemented by sleek, uncluttered lines.",
  },
];

export const Home = () => {
  const [showBookDemo, setShowBookDemo] = useState(false);
  const [bhk, setBhk] = useState([])
  const [discountData, setDiscountData] = useState([])


  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    try {
      const response = await axios.get(DiscountPath);
      const data = response?.data;
      setDiscountData(data);

    } catch (error) {
    } finally {
    }
  };




  useEffect(() => {
    axios
      .get(BhkPath)
      .then((response) => {
        setBhk(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data from API ", error);
      });
  }, []);

  const interior = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const BookDemoHandler = () => {
    setShowBookDemo(true);
  };

  return (
    <div className="home">
      <div className="h-screen ">
        <Carousel 
          // theme={CustomFlowbiteTheme}
          pauseOnHover
          arrows={false}
          className="slider-container"
          
        >

          <div className="transition-opacity duration-1000 banner banner-1">
            <div className="grid lg:grid-cols-2 banner-body">
              <div>
                <h1>Revamp your home like never before.</h1>
                <p>
                  Transform your home into an aesthetic marvel that mirrors your
                  unique style. With perfection, precision, and class.
                </p>
                <button onClick={BookDemoHandler} className="btn btn-primary">
                  Get Your Quote
                </button>
              </div>
            </div>
          </div>
          <div className="banner banner-2">
            <div className="grid lg:grid-cols-2 banner-body">
              <div>
                <h1>It's Classic. It’s Unique. It’s Yours</h1>
                <p>
                  Effortlessly crafting spaces that capture your essence and
                  embody your personality. One design at a time. Excellence
                  personified.
                </p>
                <button onClick={BookDemoHandler} className="btn btn-primary">
                  Get Your Quote
                </button>
              </div>
            </div>
          </div>
          <div className="banner banner-3">
            <div className="grid lg:grid-cols-2 banner-body">
              <div>
                <h1>Featuring stunning interior design</h1>
                <p>
                  Transforming your space into a reflection of your style and
                  personality, one design at a time.
                </p>
                <button onClick={BookDemoHandler} className="btn btn-primary">
                  Get Your Quote
                </button>
              </div>
            </div>
          </div>
        </Carousel>
        <a
          className="Whats"
          href="https://wa.me/8891639345"
          target="_blank"
          tabindex="0"
        >
          <img className="" src={Whatsapp} alt="" />
        </a>
        <img className="dis" src={ImgPath + discountData[0]?.image} alt="" />
        <img className="yer" src={tenYr} alt="" />
      </div>
      <section className="px-4 section ourProducts lg:px-20">
        <div className="title-section">
          <h5 className="sm-head">our products</h5>
          <h4 className="sub-head">
            A comprehensive destination for all your interior needs.
          </h4>
          <p>
            Whether you seek complete interior solutions, renovations, or
            modular options, we offer a comprehensive range for both home and
            office needs. From furniture to decor, we provide support throughout
            your project journey.
          </p>
        </div>
        <div>
          <ProductCard />
        </div>
      </section>
      <section className="px-4 section lg:px-20 bg-[#F5F6FF] ourPackages" >
        <div className="title-section">
          <h5 className="sm-head">Our Packages </h5>
          <h4 className="sub-head">Choose the best. From the best.</h4>
          <p>Experience the Blend of Comfort and Class.</p>
        </div>
        <div>
          <div className="grid gap-6 m-auto sm:grid-cols-2">
            <div className="cards">
              <div className>
                <div className="cards-head cards-head-2b ">
                  <div className="flex items-center gap-3 pb-2 sm:pb-4 sm:gap-6 ">
                    <h6 className="text-3xl head sm:text-4xl ">2 BHK</h6>
                    <span className="inline-flex items-center px-2 py-1 text-sm font-medium text-pink-700 rounded-md bg-purple-50 ring-1 ring-inset ring-purple-700/10 sm:text-base">
                      Standard
                    </span>
                  </div>
                  <div>
                    <div className="flex items-baseline justify-between mb-1 ">
                      <span className="text-sm text-white sm:text-base">
                        with WPC
                      </span>
                      <span className="text-lg font-bold text-white sm:text-xl">
                        ₹ {bhk[0]?.wpc}/-
                      </span>
                    </div>
                    <div className="flex items-baseline justify-between mb-1 ">
                      <span className="text-sm text-white sm:text-base ">
                        with GREEN PLY
                      </span>
                      <span className="text-lg font-bold text-white sm:text-xl">
                        ₹ {bhk[0]?.green_ply}/-
                      </span>
                    </div>
                    <div className="flex items-baseline justify-between ">
                      <span className="text-sm text-white sm:text-base">
                        with MARINE PLY 710 IS
                      </span>
                      <span className="text-lg font-bold text-white sm:text-xl">
                        ₹ {bhk[0]?.marine_ply}/-
                      </span>
                    </div>
                  </div>
                </div>
                <div className="cards-body">
                  <div>
                    <div className="flex items-center gap-2">
                      <img className="point" src={iconDinning} alt="" />
                      <span className="text-base font-bold ">KITCHEN</span>
                      <span className="text-base font-bold ">65 sq.ft</span>
                    </div>
                    <div className="">
                      <span className="text-base font-medium">
                        Kitchen Accessories :-
                      </span>
                      <span className="text-sm text-slate-700">
                        Tandem box(3nos) pvc cutlery tray bottle pullout, Waste
                        bin-door hangtype hood & hob (faber)
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center gap-2 mt-5">
                      <img className="point" src={iconKitchen} alt="" />
                      <span className="text-base font-bold ">DINING</span>
                      <span className="text-base font-bold ">4.5 sq.ft</span>
                    </div>
                    <div className="">
                      <span className="text-sm text-slate-700">
                        Common wash counter with mirror
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center gap-2 mt-5 ">
                      <img className="point" src={iconData} alt="" />
                      <span className="text-base font-bold ">
                        MASTER BEDROOM
                      </span>
                    </div>
                    <div className="">
                      <span className="text-sm text-slate-700">
                        Cot with fabric headboard & storage(king size), Bedside
                        table (2nos) 2.25 sq.Ft, 3 door swing wardrobe 31.5
                        sq.Ft, Dressing table with mirror 8.25sqft
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center gap-2 mt-5 ">
                      <img className="point" src={iconData} alt="" />
                      <span className="text-base font-bold ">BEDROOM</span>
                    </div>
                    <div className="">
                      <span className="text-sm text-slate-700">
                        Cot with fabric headboard & storage(queen size), Bedside
                        table (1 nos) 2.25 sq.Ft, 3 door swing wardrobe 31.5
                        sq.Ft
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center gap-2 mt-5 ">
                      <img className="point" src={iconShose} alt="" />
                      <span className="text-base font-bold ">SHOE RACK</span>
                      <span className="text-base font-bold ">6 sq.ft</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-4 text-center sm:p-6">
                <button className="btn btn-primary" onClick={BookDemoHandler}>
                  Get Free Quote
                </button>
              </div>
            </div>
            <div className="cards">
              <div className="cards-head cards-head-3b ">
                <div className="flex items-center gap-3 pb-2 sm:pb-4 sm:gap-6 ">
                  <h6 className="text-2xl head sm:text-4xl ">3 BHK</h6>
                  <span className="inline-flex items-center px-2 py-1 text-sm font-medium text-purple-700 rounded-md bg-purple-50 ring-1 ring-inset ring-purple-700/10 sm:text-base">
                    Premium
                  </span>
                </div>
                <div>
                  <div className="flex items-baseline justify-between mb-1 ">
                    <span className="text-sm text-white sm:text-base">
                      with WPC
                    </span>
                    <span className="text-lg font-bold text-white sm:text-xl">
                      ₹ {bhk[1]?.wpc}/-
                    </span>
                  </div>
                  <div className="flex items-baseline justify-between mb-1 ">
                    <span className="text-sm text-white sm:text-base">
                      with GREEN PLY
                    </span>
                    <span className="text-lg font-bold text-white sm:text-xl">
                      ₹ {bhk[1]?.green_ply}/-
                    </span>
                  </div>
                  <div className="flex items-baseline justify-between ">
                    <span className="text-sm text-white sm:text-base ">
                      with MARINE PLY 710 IS
                    </span>
                    <span className="text-lg font-bold text-white sm:text-xl">
                      ₹ {bhk[1]?.marine_ply}/-
                    </span>
                  </div>
                </div>
              </div>
              <div className="cards-body">
                <div>
                  <div className="flex items-center gap-2 ">
                    <img className="point" src={iconDinning} alt="" />
                    {/* <span className="point"></span> */}

                    <span className="text-base font-bold ">KITCHEN</span>
                    <span className="text-base font-bold ">65 sq.ft</span>
                  </div>
                  <div className="">
                    <span className="text-base font-medium">
                      Kitchen Accessories :-
                    </span>
                    <span className="text-sm text-slate-700">
                      Tandem box(3nos) pvc cutlery tray bottle pullout, Waste
                      bin-door hangtype hood & hob (faber)
                    </span>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-2 mt-5 ">
                    <img className="point" src={iconKitchen} alt="" />
                    <span className="text-base font-bold ">DINING</span>
                    <span className="text-base font-bold ">4.5 sq.ft</span>
                  </div>
                  <div className="">
                    <span className="text-sm text-slate-700">
                      Common wash counter with mirro, Dining table with 6 chairs
                    </span>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-2 mt-5 ">
                    <img className="point" src={iconData} alt="" />
                    <span className="text-base font-bold ">MASTER BEDROOM</span>
                  </div>
                  <div className="">
                    <span className="text-sm text-slate-700">
                      Cot with fabric headboard & storage(king size), Bedside
                      table (2nos) 4.5 sq.Ft, 4 door swing wardrobe 42 sq.Ft
                      ,Dressing table with mirror 8.25 sqft, Vanity unit with
                      mirror 4.5 sq.Ft
                    </span>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-2 mt-5 ">
                    <img className="point" src={iconData} alt="" />
                    <span className="text-base font-bold ">BEDROOM 1</span>
                  </div>
                  <div className="">
                    <span className="text-sm text-slate-700">
                      Cot with fabric headboard & storage(queen size), Bedside
                      table (1 nos) 2.25 sq.Ft, 3 door swing wardrobe 31.5
                      sq.Ft, Vanity unit with mirror 4.5 sq.Ft
                    </span>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-2 mt-5 ">
                    <img className="point" src={iconData} alt="" />
                    <span className="text-base font-bold ">BEDROOM 2</span>
                  </div>
                  <div className="">
                    <span className="text-sm text-slate-700">
                      Cot with fabric headboard & storage(queen size), Bedside
                      table (1 nos) 2.25 sq.Ft, 3 door swing wardrobe 31.5
                      sq.Ft, Vanity unit with mirror 4.5 sq.Ft,
                    </span>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-2 mt-5 ">
                    <img className="point" src={iconShose} alt="" />
                    <span className="text-base font-bold ">SHOE RACK</span>
                    <span className="text-base font-bold ">6 sq.ft</span>
                  </div>
                </div>
              </div>
              <div className="pb-4 text-center sm:pb-6">
                <button className="btn btn-primary " onClick={BookDemoHandler}>
                  Get Free Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="px-4 section ourWorks lg:px-20">
        <div className="title-section">
          <h5 className="sm-head">our works</h5>
          <h4 className="sub-head">
            Ideas to spark your home interior creativity.
          </h4>
          <p>
            Revitalize your living space with handpicked interior design
            concepts tailored just for you.
          </p>
        </div>

        <div className="lg:mt-8 sm:mt-4">
          <GallerySrc></GallerySrc>
        </div>
        <div className="mt-6 text-center">
          <NavLink to="/OurWorks" element-id="123">
            <span className="btn btn-primary"> View All </span>
          </NavLink>
        </div>
      </section>
      <section className="px-4 section ideas lg:px-20">
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
          <div className="idea-data">
            <div className="line"></div>
            <h6 className="sub-head">Visualize your ideas with us</h6>

            <p>
              We are ready to help you build and also realize the room design
              that you dream of, with our experts and also the best category
              recommendations from us
            </p>

            <FirstAccordion item={Vs} />
          </div>

          <div className="idea-img">
            <img className="img-rotation" src={vsTwo} alt="" />

            <img src={idea} alt="" />
            <img className="img-sub" src={vsOne} alt="" />
          </div>
        </div>
        <div></div>
      </section>
      <section className="px-4 section why-choose-us lg:px-20">
        <div className="grid gap-10 sm:grid-cols-1 lg:grid-cols-2">
          <div>
            <div className="grid grid-cols-2 gap-3 md:gap-6 lg:grid-cols-3 ">
              <div className="card-icon">
                <div className="icon">
                  <img src={Year} alt="" />
                </div>

                <h6>6 Year Warranty</h6>
              </div>
              <div className="card-icon">
                <div className="icon">
                  <img src={Emi} alt="" />
                </div>

                <h6>Easy EMIs</h6>
              </div>
              <div className="card-icon">
                <div className="icon">
                  <img src={Guarantee} alt="" />
                </div>

                <h6>45 Days Guarantee</h6>
              </div>
              <div className="card-icon">
                <div className="icon">
                  <img src={Checks} alt="" />
                </div>

                <h6>146 Quality Checks</h6>
              </div>
              <div className="card-icon">
                <div className="icon">
                  <img src={home} alt="" />
                </div>

                <h6>More Happy Homes</h6>
              </div>
              <div className="card-icon">
                <div className="icon">
                  <img src={Citie} alt="" />
                </div>

                <h6>50+ Cities</h6>
              </div>
            </div>
          </div>
          <div className="why-choose-us-body">
            <div className="lg:text-left">
              <div className="text-center lg:text-left ">
                <h5 className="sm-head">Why choose us</h5>
              </div>
              <h4 className="text-center sub-head lg:text-left ">
                Boasts stunning interior design.
              </h4>
            </div>
            <p className="text-center lg:text-left">
              Welcome to our interior design company, where creativity meets
              functionality to redefine spaces. With a passion for aesthetics
              and a commitment to excellence, we specialize in crafting bespoke
              interiors tailored to your unique vision and needs. From concept
              development to project completion, our team of skilled designers
              and architects collaborates closely with clients to bring their
              dreams to life. Whether it's a residential makeover, commercial
              renovation, or hospitality project, we pride ourselves on
              delivering innovative solutions that surpass expectations.
            </p>
            <div className="sm:text-center lg:text-left">
              <NavLink to="/AboutUs">
                <span className="btn btn-primary">Know More </span>
              </NavLink>
            </div>
          </div>
        </div>
        <div></div>
      </section>
      <section className="obtain">
        <h3>Obtain a quotation for your residence.</h3>
        <p>
          Elevate your space with our bespoke interior designs, tailored to
          reflect your unique style and personality. Experience the perfect
          blend of aesthetics and functionality with our expert team.
        </p>
        <div>
          <button onClick={BookDemoHandler} className="btn btn-secondary">
            Get your Free Quote
          </button>
        </div>
      </section>
      <section className="px-4 section why-choose-us lg:px-20">
        <h6 className="text-center sub-head ">
          Frequently Asked Questions (FAQs)
        </h6>
        <div className="lg:mt-10">
          <Accordion item={FAQ}></Accordion>
        </div>
      </section>
      <ModalBook
        isVisible={showBookDemo}
        onClose={() => setShowBookDemo(false)}
      />

      <Footer></Footer>
    </div>
  );
};
