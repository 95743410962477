import React, { useState, useEffect, useRef } from "react";
import "./pages.css";
import { CardForm } from "../components/CardForm";
import { Footer } from "../components/Footer";
import Stepper from "../components/Stepper";
import {Wizard} from "../components/Stepper";
import axios from "axios";
import Whatsapp from "../img/whatsapp.png";
import tenYr from "../img/6yrs.png";

const ImgPath = process.env.REACT_APP_IMg_URL;
const DiscountPath = process.env.REACT_APP_API_DISCOUN;



const CalculatorPath = process.env.REACT_APP_API_CALCULATOR;

export const PriceCalculator = () => {
  const [data, setData] = useState([]);
  const [discountData, setDiscountData] = useState([])


  const DivRef = useRef(null);
  
  
  
  const steps = [{ title: "Step 1" }, { title: "Step 2" }, { title: "Step 3" }];

  useEffect(() => {
  }, []);





  const fetchData = async () => {

    try {
      const response = await axios.get(CalculatorPath);
      const data = response?.data
      setData(data)
      
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    fetchData();
    fetchDiscount();

  }, []);
  useEffect(() => {
    if (DivRef.current) {
      DivRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  });
  const fetchDiscount = async () => {
    try {
      const response = await axios.get(DiscountPath);
      const data = response?.data;
      setDiscountData(data);

    } catch (error) {
    } finally {
    }
  };
  return (
    <div className="priceCalculator pages" ref={DivRef}>
      <div className=" modular">
        <div className="sm-banner">
          <div className="banner-body">
            <div>
              <h1> Calculate the budget.</h1>
            </div>
          </div>

          <div></div>
        </div>
      </div>
      <a
          className="Whats"
          href="https://wa.me/8891639345"
          target="_blank"
          tabindex="0"
        >
          <img className="" src={Whatsapp} alt="" />
        </a>
        <img className="dis" src={ImgPath + discountData[0]?.image} alt="" />
        <img className="yer" src={tenYr} alt="" />
      <Wizard steps={steps}  PlyInfo={data} />
      <Footer></Footer>
    </div>
  );
};
