import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import logo from "../img/logo.png";
import facbook from '../icon/logo-facebook.png'
import twitter from '../icon/logo-twitter.png';
import insta from '../icon/logo-instagram.png';


export const Footer = () => {


    return (
        <div className='footer'>
            <div className='dark-bg p-t-b sm:px-5 md:px-20'>
                <div className='grid grid-cols-2 gap-5 md:grid-cols-5'>
                    <div className='col-span-2 px-1 footer-head lg:px-0 lg:col-span-1' >
                        <img src={logo} alt="log" className='logo' />
                        <p>Transforming spaces with elegance and innovation. Your dream home awaits, curated by our expert designers.</p>
                    </div>
                    <div>
                        <h6 className='head'>Features</h6>
                        <ul>
                           
                            <li>
                                <NavLink  >Price Calaculator</NavLink>
                            </li>
                            <li>
                            <NavLink to="/OurWorks">Our Works</NavLink>
                            </li>
                            <li>
                            <NavLink to="/AboutUs">About Us</NavLink>
                            </li>
                            <li>
                            <NavLink to="/Contact">Contact Us</NavLink>
                            </li>


                        </ul>
                    </div>
                    <div>
                        <h6 className='head'>Our Products </h6>

                        <ul>
                           
                            <li>
                                <NavLink to="/OurProducts" >Modular Interiors</NavLink>
                            </li>
                            <li>
                                <NavLink to="/HomeInteriors" >Full Home Interiors</NavLink>
                            </li>
                            <li>
                                <NavLink to="/LuxuryInteriors" >Luxury Interiors</NavLink>
                            </li>
                            <li>
                                <NavLink to="/Renovations" >Renovations</NavLink>
                            </li>
                            <li>
                                <NavLink to="/Commercial" >Commercial Interiors</NavLink>
                            </li>


                        </ul>
                    </div>
                    <div>
                        <h6 className='head'>Legal</h6>

                        <ul>
                        <li>
                                <NavLink >FAQ</NavLink>
                            </li>
                            <li>
                                <NavLink>Privacy Policy</NavLink>
                            </li>
                            <li>
                                <NavLink>Terms of  Use</NavLink>
                            </li>


                        </ul>
                    </div>
                    <div className='flex flex-col gap-6'>
                        <h6 className='head'>Social Media</h6>

                        <div className='flex justify-start gap-5 px-4'>
                            <a href='#'><img src={facbook} alt="" /></a>
                            <a href='#'><img src={insta} alt="" /></a>
                            <a href='#'><img src={twitter} alt="" /></a>
                        </div>
                    </div>
                </div>
                <div className='copy'>
                    <small>© 2024  Stylo Interior. All rights reserved | Powered By: <a href='https://www.kreativesparkz.com/' target='_blank	'> Kreative Sparkz Ad Media </a> </small>

                </div>
            </div>

        </div>
    )
}
