import React from "react";
import Slider from "react-slick";
import { NavLink, useHistory } from 'react-router-dom';




const ProductCard = () => {
    
const settings = {
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // tablet breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
  
      {
        breakpoint: 480, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }],
  
  
  };
  return (
    <div>
      <div className="grid grid-cols-1">
        <Slider {...settings}>
          <div>
            <div className="flex flex-col justify-between card card-products card-products-one">
              <div>
                <small>Elevating spaces with inspired design.</small>
                <h6>Modular Interiors </h6>
              </div>
              <div className="flex justify-end ">
              <NavLink to="/OurProducts" className="arrow-click">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </NavLink>
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col justify-between card card-products card-products-two">
              <div>
                <small>Elevating spaces with inspired design.</small>
                <h6>Full Home Interiors</h6>
              </div>
              <div className="flex justify-end ">
              <NavLink to="/HomeInteriors"  className="arrow-click" >
               
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </NavLink>
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col justify-between card card-products card-products-three">
              <div>
                <small>Elevating spaces with inspired design.</small>
                <h6>Luxury Interiors</h6>
              </div>
              <div className="flex justify-end ">
              <NavLink to="/LuxuryInteriors" className="arrow-click" >
                 
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </NavLink>
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col justify-between card card-products card-products-four">
              <div>
                <small>Elevating spaces with inspired design.</small>
                <h6>Renovations</h6>
              </div>
              <div className="flex justify-end ">
              <NavLink to="/Renovations" className="arrow-click" href="#">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </NavLink>
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col justify-between card card-products card-products-five">
              <div>
                <small>Simplify commercial interior projects.</small>
                <h6>Commercial Interiors</h6>
              </div>
              <div className="flex justify-end ">
              <NavLink to="/Commercial" className="arrow-click" href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </NavLink>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default ProductCard;
