import React, { useState, useEffect } from "react";
import axios from "axios";
import { Footer } from "../components/Footer";
import { CardForm } from "../components/CardForm";
import "./pages.css";
import Whatsapp from "../img/whatsapp.png";
import tenYr from "../img/6yrs.png";
const ImgPath = process.env.REACT_APP_IMg_URL;
const DiscountPath = process.env.REACT_APP_API_DISCOUN;

export const ContactUs = () => {
  const [discountData, setDiscountData] = useState([])

  useEffect(() => {
    fetchDiscount();
  }, []);


  const fetchDiscount = async () => {
    try {
      const response = await axios.get(DiscountPath);
      const data = response?.data;
      setDiscountData(data);

    } catch (error) {
    } finally {
    }
  };

  return (
    <div className="contactUs pages">
      <div className="form-bg">
        <section className=" sec p-t-b px-4 lg:px-2 section ">
          <div className="card-form-head ">
            <h1 className="sub-head "> Contact Us</h1>
          </div>
          <CardForm className="h-screen" />
          <a
          className="Whats"
          href="https://wa.me/8891639345"
          target="_blank"
          tabindex="0"
        >
          <img className="" src={Whatsapp} alt="" />
        </a>
        <img className="dis" src={ImgPath + discountData[0]?.image} alt="" />
        <img className="yer" src={tenYr} alt="" />
        </section>
      </div>
      <Footer />
    </div>
  );
};
