import React, { useState, useEffect } from "react";
import "./gallery.css";
import axios from "axios";
// import Lightbox from "yet-another-react-lightbox";
// import "yet-another-react-lightbox/styles.css";

import LightGallery from "lightgallery/react";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

const localPath = process.env.REACT_APP_API_KEY;

export const AllGallery = () => {
  const [open, setOpen] = React.useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchImg();
  }, []);

  const fetchImg = async () => {
    try {
      const response = await axios.get(
        "https://stylointerior.com/stylo/stylo_api/api_gallery.php"
      );
      const ImgData = response.data;

      setImages(ImgData.reverse());
    } catch (error) {
    } finally {
    }
  };

  return (
    <div>
      <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]} className="grid-wrapper"> 
      {images?.map(({image ,title}) => (
      
          <a href={localPath + image} className="grid-img">
            <img src={localPath + image} alt="" />
         <h4 className="over-text">{title}</h4>
          </a>
      ))}
        
      </LightGallery>





    </div>
  );
};
