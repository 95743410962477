import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";

import { Accordion } from "../components/Acoordin";
import { Footer } from "../components/Footer";
import Slider from "react-slick";
import ProductCard from "../components/ProductCard";
import { CardForm } from "../components/CardForm";
import vsOne from "../img/idea-1.png";
import vSmain from "../img/products/CommercialInteriors/modular-bg.png";
import subModular from "../img/products/CommercialInteriors/modular-sub.png";
import Whatsapp from "../img/whatsapp.png";
import tenYr from "../img/6yrs.png";



import "./pages.css";
const ImgPath = process.env.REACT_APP_IMg_URL;
const DiscountPath = process.env.REACT_APP_API_DISCOUN;


const FAQ = [
  {
    title: "How do I get started with your interior design services?",
    content:
    "Just give us a call to schedule a consultation where we can discuss your design goals and preferences.",
  },
  {
    title:
      "What is the typical timeline for completing an interior design project?",
    content:
    "There’s no specific timeline for completing a project. It depends upon the requirements of the client and the availability of materials",
  },
  {
    title: "Can I incorporate my existing furniture and decor into the design?",
    content:
      "Of Course, you can integrate your existing furniture and décor into the design. It will be a good blend of old and new",
  },
  {
    title: "Can you renovate my existing home?",
    content:
      "Yes. Your existing home can be renovated and redesigned according to your taste.",
  },
  {
    title: "Can I suggest changes in the design after the order is confirmed?",
    content:
      "Of Course. You have the freedom to suggest alternations even after the design is confirmed, but once the final product is complete it can be impossible to make changes.",
  },
  {
    title: "Who will do the installation for my home? ",
    content:
    "Your home will revamped by a talented team of professional architects and    interior designers. We’ll leave no stone unturned. "
   },
   {
    title: "How much is the Warranty Period?",
    content:
    "We are a step ahead when it comes to Warranty. Our period is 6 years!"
  },
  {
   title: "What kinds of materials do you use?",
   content:
   "We use Wood-Plastic Composites (WPC), Wood Ply, and Marine Ply for our products"
  },
  {
    title: "How much does it cost for a whole project?",
    content:
    "The overall project cost depends upon the materials used and the finalized design  details. Our designers will be able to provide the exact and every stage of the project"
   },
   {
    title: "Do you accept payments in EMI?",
    content:
    "Once the project is finalized, we will provide our payment schedule at the beginning of the project, then you can facilitate payments by credit/debit cards or EMI if it’s available at your bank."
   },
];
const settings = {
  dots: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  // autoplay: true,
  autoplaySpeed: 2000,
  dots: true,
  arrows: true,
  dots: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768, // tablet breakpoint
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 480, // mobile breakpoint
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const Commercial = () => {
  const [discountData, setDiscountData] = useState([])

  const DivRef = useRef(null);
  useEffect(() => {
    fetchDiscount();
  }, []);

  const fetchDiscount = async () => {
    try {
      const response = await axios.get(DiscountPath);
      const data = response?.data;
      setDiscountData(data);

    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    if (DivRef.current) {
      DivRef.current.scrollIntoView({
          behavior: 'smooth'
      });
  }
  


  })
  return (
    <div className="product-page pages" ref={DivRef}>
      <div className=" modular Commercial">
        <div className="sm-banner">
          <div className="banner-body">
            <div>
              <h1>Simplify your commercial interior projects with ease.</h1>
            </div>
            
          </div>

          <div></div>
        </div>
      </div>
      <a
          className="Whats"
          href="https://wa.me/8891639345"
          target="_blank"
          tabindex="0"
        >
          <img className="" src={Whatsapp} alt="" />
        </a>
        <img className="dis" src={ImgPath + discountData[0]?.image} alt="" />
        <img className="yer" src={tenYr} alt="" />
      <section className="px-4 section ideas lg:px-20 bg-">
        <div className="body-product-pg">
          <div className="grid gap-8 lg:grid-cols-2 sm:grid-cols-1">
            <div className="product-pg">
              <img className="modukar-img" src={vSmain} alt="" />
              <img className="img-sub" src={vsOne} alt="" />
            </div>
            <div className="flex flex-col gap-3 align-middle lg:mt-9">
              <h2>
              Setting new standards for commercial interiors across various sectors.

              </h2>
              <p className="w">
              We aspire to revolutionize commercial interiors by offering a fresh perspective by crafting spaces that prioritize the creation of aesthetic, functional environments that are ready for the future. We strive to redefine traditional concepts and aim to deliver spaces that inspire creativity, and productivity along with fostering collaboration.
              </p>
              <p>
              With our commitment to excellence and forward-thinking design principles and philosophies, we are stepping into a new era of commercial interiors that are both cutting-edge and timeless.

              </p>
              <div className="grid sm:grid-cols-1 lg:grid-cols-2">
                <div></div>
                <div className="img-sm-sub">
                  <img src={subModular} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="px-4 section lg:px-20 works-product light-bg">
        <div className="title-section">
          <h5 className="sm-head">our works</h5>
          <h4 className="sub-head">
            Ideas to spark your home interior creativity.
          </h4>
          <p>
            Revitalize your living space with handpicked interior design
            concepts tailored just for you.
          </p>
        </div>
        <div>
          <ProductCard />
        </div>
      </section>
      <section className="px-4 section ideas lg:px-20">
      <h6 className="text-center sub-head ">
          Frequently Asked Questions (FAQs)
        </h6>
        <div className="grid grid-cols-1 gap-10">
          <Accordion item={FAQ}></Accordion>
        </div>
      </section>
      <section className="px-4 section lg:px-20 form-bg">
        <CardForm />
      </section>

      <Footer></Footer>
    </div>
  );
};
