import React, { useState, useEffect } from "react";
import "./checkbox.css";
import warranty from "../../icon/warranty.png";

const ImgPath = process.env.REACT_APP_IMg_URL;

const Checkbox = (props) => {
  const [ply, setPly] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionId, setSelectedOptionId] = useState(props?.PlyData[0]?.id);

  const handleOptionChange = (e) => {
    const selectedValue = JSON.parse(e.target.value); // Deserialize the string back into an object
    
    setSelectedOption(selectedValue);
    props.onSelectedPly(selectedValue);
    setSelectedOptionId(selectedValue?.id);
  };
  useEffect(() => {
    setPly(props?.PlyData);
  }, [props]);
  useEffect(() => {
    props.onSelectedPly(props?.PlyData[0]);
  }, []);
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div>
      <div className="grid gap-6 mt-5 sm:grid-cols-2">
        {ply?.map((option) => (
          <div>
            <label for={`radio-card${option?.id}`} className="grid radio-card">
              <input
                type="radio"
                name="radio-card"
                id={`radio-card${option?.id}`}
                onChange={handleOptionChange}
                checked={selectedOptionId === option?.id}
                value={JSON.stringify(option)}
              />
              <div className="card-content-wrapper">
                <span className="check-icon"></span>
                <div className="card-content">
                  <img className="plyimg" src={ImgPath + option?.image} alt="" />
                  <div className="card-content-wrapper-body">
                    <h4>{option?.title}</h4>
                    <h3 className="text-lg">
                      Rs:{numberWithCommas(Number(option?.rupees) + Number(props?.frontEnd))}/sqft
                    </h3>
                    <div className="flex items-center gap-1">
                      <img className="w-4 h-4 " src={warranty} alt="" />{" "}
                      <h5>{option?.year}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Checkbox;
