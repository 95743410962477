import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
// import Thankyou from "./pages/thankyou";




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    {/* <Router>
        <Routes>
          <Route path="/Thankyou" element={<Thankyou />}></Route>
        </Routes>
      </Router> */}
  </React.StrictMode>
);



