import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import "./pages.css";
import { Footer } from "../components/Footer";
import { Accordion } from "../components/Acoordin";
import { CardForm } from "../components/CardForm";

import faceOne from "../img/face-1.png";
import faceTwo from "../img/face-2.png";
import Whatsapp from "../img/whatsapp.png";
import tenYr from "../img/6yrs.png";

const ImgPath = process.env.REACT_APP_IMg_URL;
const DiscountPath = process.env.REACT_APP_API_DISCOUN;
const FAQ = [
  {
    title: "How do I get started with your interior design services?",
    content:
    "Just give us a call to schedule a consultation where we can discuss your design goals and preferences.",
  },
  {
    title:
      "What is the typical timeline for completing an interior design project?",
    content:
    "There’s no specific timeline for completing a project. It depends upon the requirements of the client and the availability of materials",
  },
  {
    title: "Can I incorporate my existing furniture and decor into the design?",
    content:
    "Of Course, you can integrate your existing furniture and décor into the design. It will be a good blend of old and new",
  },
  {
    title: "Can you renovate my existing home?",
    content:
      "Yes. Your existing home can be renovated and redesigned according to your  taste.",
  },
  {
    title: "Can I suggest changes in the design after the order is confirmed?",
    content:
      "Of Course. You have the freedom to suggest alternations even after the design is confirmed, but once the final product is complete it can be impossible to make changes.",
  },
  {
    title: "Who will do the installation for my home? ",
    content:
    "Your home will revamped by a talented team of professional architects and    interior designers. We’ll leave no stone unturned. "
   },
   {
    title: "How much is the Warranty Period?",
    content:
    "We are a step ahead when it comes to Warranty. Our period is 6 years!"
  },
  {
   title: "What kinds of materials do you use?",
   content:
   "We use Wood-Plastic Composites (WPC), Wood Ply, and Marine Ply for our products"
  },
  {
    title: "How much does it cost for a whole project?",
    content:
    "The overall project cost depends upon the materials used and the finalized design  details. Our designers will be able to provide the exact and every stage of the project"
   },
   {
    title: "Do you accept payments in EMI?",
    content:
    "Once the project is finalized, we will provide our payment schedule at the beginning of the project, then you can facilitate payments by credit/debit cards or EMI if it’s available at your bank."
   },
];

export const AboutUs = () => {
  const [discountData, setDiscountData] = useState([])

  const DivRef = useRef(null);
  useEffect(() => {
  }, []);


  const fetchData = async () => {
    try {
      const response = await axios.get(DiscountPath);
      const data = response?.data;
      setDiscountData(data);

    } catch (error) {
    } finally {
    }

  };

  useEffect(() => {
    if (DivRef.current) {
      DivRef.current.scrollIntoView({
          behavior: 'smooth'
      });
      fetchData();

  }
})
  return (
    <div>
      <div className="About-us pages" ref={DivRef}>
        <div className=" modular">
          <div className="sm-banner">
            <div className="banner-body">
              <h1> Know more us</h1>

            </div>
          </div>
        </div>
        <a
          className="Whats"
          href="https://wa.me/8891639345"
          target="_blank"
          tabindex="0"
        >
          <img className="" src={Whatsapp} alt="" />
        </a>
        <img className="dis" src={ImgPath + discountData[0]?.image} alt="" />
        <img className="yer" src={tenYr} alt="" />

        {/* <div className="section light-bg">
          <div className="title-section">
            <h5 className="sm-head">our founders</h5>
            <h4 className="sub-head">Meet our founders</h4>
          </div>

          <div className="grid grid-cols-2 gap-5 m-auto lg:grid-cols-4 lg:w-9/6 lg:gap-0 ">
            <div className="flex flex-col items-center gap-2 lg:gap-4 card-founders sm:mt-3 lg:mt-16">
              <div>
                <img className="img-card" src={faceTwo} alt="" />
              </div>
              <div className="text-center ">
                <h6 className="text-xl font-bold">Don Kumar M V</h6>
                <span className="text-sm">Co-founder and CEO</span>
              </div>
              <div>
                <p className="text-center lg:w-5/6 sm:w-auto">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-2 lg:gap-4 card-founders sm:mt-3 lg:mt-16">
              <div>
                <img className="img-card" src={faceOne} alt="" />
              </div>
              <div className="text-center ">
                <h6 className="text-xl font-bold">Don Kumar M V</h6>
                <span className="text-sm">Co-founder and CEO</span>
              </div>
              <div>
                <p className="text-center lg:w-5/6 sm:w-auto">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-2 lg:gap-4 card-founders sm:mt-3 lg:mt-16">
              <div>
                <img className="img-card" src={faceTwo} alt="" />
              </div>
              <div className="text-center ">
                <h6 className="text-xl font-bold">Don Kumar M V</h6>
                <span className="text-sm">Co-founder and CEO</span>
              </div>
              <div>
                <p className="text-center lg:w-5/6 sm:w-auto">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-2 lg:gap-4 card-founders sm:mt-3 lg:mt-16">
              <div>
                <img className="img-card" src={faceOne} alt="" />
              </div>
              <div className="text-center ">
                <h6 className="text-xl font-bold">Don Kumar M V</h6>
                <span className="text-sm">Co-founder and CEO</span>
              </div>
              <div>
                <p className="text-center lg:w-5/6 sm:w-auto">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <section className="px-4 section ideas lg:px-20">
        <h6 className="text-center sub-head ">
          Frequently Asked Questions (FAQs)
        </h6>
          <div className="grid grid-cols-1 gap-10">
            <Accordion item={FAQ}></Accordion>
          </div>
        </section>
        <section className="px-4 section lg:px-20 form-bg">
          <CardForm />
        </section>
        <Footer></Footer>
      </div>
    </div>
  );
};
