import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { NavBar } from "./components/NavBar";

import { Home } from "./pages/Main";
import { AboutUs } from "./pages/AboutUs";
import { ContactUs } from "./pages/ContactUs";
import { OurProducts } from "./pages/OurProducts ";
import { OurWorks } from "./pages/OurWorks";
import { PriceCalculator } from "./pages/PriceCalculator";
import { HomeInteriors } from "./pages/HomeInteriors";
import { LuxuryInteriors } from "./pages/LuxuryInteriors";
import { Renovations } from "./pages/Renovation";
import { Commercial } from "./pages/CommercialInteriors";

function App() {
  return (
    <div className="App">
      <Router>
        <div className="relative z-50 w-full">
          <NavBar />
        </div>
        <div className="pages">
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/AboutUs" element={<AboutUs />}></Route>
            <Route path="/OurProducts" element={<OurProducts />}></Route>
            <Route path="/Contact" element={<ContactUs />}></Route>
            <Route path="/OurWorks" element={<OurWorks />}></Route>
            <Route
              path="/PriceCalculator"
              element={<PriceCalculator />}
            ></Route>
            <Route path="/HomeInteriors" element={<HomeInteriors />}></Route>
            <Route
              path="/LuxuryInteriors"
              element={<LuxuryInteriors />}
            ></Route>
            <Route path="/Renovations" element={<Renovations />}></Route>
            <Route path="/Commercial" element={<Commercial />}></Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
