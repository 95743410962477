import React, { useState, useEffect } from "react";
import "./gallery.css";
import axios from "axios";
// import Lightbox from "yet-another-react-lightbox";
// import "yet-another-react-lightbox/styles.css";

import LightGallery from "lightgallery/react";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

const localPath = process.env.REACT_APP_API_KEY;

export const GallerySrc = () => {
  const [open, setOpen] = React.useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchImg();
  }, []);

  const fetchImg = async () => {
    try {
      const response = await axios.get(
        "https://stylointerior.com/stylo/stylo_api/api_gallery.php"
      );
      const ImgData = response.data;

      setImages(ImgData.reverse());
    } catch (error) {
    } finally {
    }
  };

  return (
    <div>
      <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]} className="grid-wrapper">
        
          <a href={localPath + images[0]?.image} className="image-grid-col-2 grid-img">
            <img src={localPath + images[0]?.image} alt="" />
         <h4 className="over-text">{images[0]?.title}</h4>
          </a>
          <a href={localPath + images[1]?.image} className="grid-img">
            <img src={localPath + images[1]?.image} alt="" />
            <h4 className="over-text">{images[1]?.title}</h4>
          </a>
          <a href={localPath + images[2]?.image} className="grid-img">
            <img src={localPath + images[2]?.image} alt="" />
            <h4 className="over-text">{images[2]?.title}</h4>
          </a>
          <a href={localPath + images[3]?.image} className="grid-img">
            <img src={localPath + images[3]?.image} alt="" />
            <h4 className="over-text">{images[3]?.title}</h4>
          </a>
          <a href={localPath + images[4]?.image} className="image-grid-row-2 grid-img">
            <img src={localPath + images[4]?.image} alt="" />
            <h4 className="over-text">{images[4]?.title}</h4>
          </a>
          <a href={localPath + images[5]?.image}  className="image-grid-col-2 grid-img">
            <img src={localPath + images[5]?.image} alt="" />
            <h4 className="over-text">{images[5]?.title}</h4>
          </a>
          <a href={localPath + images[6]?.image} className="grid-img">
            <img src={localPath + images[6]?.image} alt="" />
            <h4 className="over-text">{images[6]?.title}</h4>
          </a>
          <a href={localPath + images[7]?.image} className="grid-img">
            <img src={localPath + images[7]?.image} alt="" />
            <h4 className="over-text">{images[7]?.title}</h4>
          </a>
          <a href={localPath + images[8]?.image} className="grid-img">
            <img src={localPath + images[8]?.image} alt="" />
            <h4 className="over-text">{images[8]?.title}</h4>
          </a>
        
      </LightGallery>

      <div>
    
      </div>
    </div>
  );
};
