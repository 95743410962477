import React, { useState, useEffect } from "react";
import "./checkbox.css";
import warranty from "../../icon/warranty.png";
// import offerIcon from "../../icon/offer.png";
import axios from "axios";

const ImgPath = process.env.REACT_APP_API_KEY;;

const CheckboxImg = (props) => {
  const [ply, setPly] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionId, setSelectedOptionId] = useState(
    props?.PlyData[0]?.id
  );

  const handleSubmit = async (e) => {

    try {
      const res = await axios.post("/email.php", selectedOption);
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };




  const handleOptionChange = (e) => {
    const selectedValue = JSON.parse(e.target.value); // Deserialize the string back into an object
    console.log("selectedValue", selectedValue)
    setSelectedOption(selectedValue);
    setSelectedOptionId(selectedValue?.id);
  };
  useEffect(() => {
    setPly(props?.PlyData);
    console.log("props?.PlyData", props?.PlyData)


  }, [props]);


  return (
    <div>
      <div>
        {ply?.map((option) => (
          <div className="grid gap-2 mt-5 grid-cols-2 sm:grid-cols-4">
            <label for={`radio-card${option?.image1}`} className="grid radio-card">
              <input
                type="radio"
                name="radio-card"
                id={`radio-card${option?.image1}`}
                onChange={handleOptionChange}
                checked={selectedOptionId === option?.id}
                value="image1"
              />
              <div className="card-content-wrapper relative sm:h-40  sm:w-[9rem] h-20 w-20" style={{ padding: "0px" }}>
                <span className="check-icon m-2 bg-white"></span>
                <div className=" absolute ">
                  <img
                    className="sm:h-40  sm:w-[9rem] h-20 w-120 rounded-[6px]"
                    src={ImgPath + option?.image1}
                    alt=""
                  />
                </div>
              </div>
            </label>
            <label for={`radio-card${option?.image2}`} className="grid radio-card">
              <input
                type="radio"
                name="radio-card"
                id={`radio-card${option?.image2}`}
                onChange={handleOptionChange}
                checked={selectedOptionId === option?.id}
                value="image2"
              />
              <div className="card-content-wrapper relative sm:h-40  sm:w-[9rem] h-20 w-20" style={{ padding: "0px" }}>
                <span className="check-icon m-2 bg-white"></span>
                <div className=" absolute ">
                  <img
                    className="sm:h-40  sm:w-[9rem] h-20 w-20 rounded-[6px]"
                    src={ImgPath + option?.image2}
                    alt=""
                  />
                </div>
              </div>
            </label>
            <label for={`radio-card${option?.image3}`} className="grid radio-card">
              <input
                type="radio"
                name="radio-card"
                id={`radio-card${option?.image3}`}
                onChange={handleOptionChange}
                checked={selectedOptionId === option?.id}
                value="image3"
              />
              <div className="card-content-wrapper relative sm:h-40  sm:w-[9rem] h-20 w-20" style={{ padding: "0px" }}>
                <span className="check-icon m-2 bg-white"></span>
                <div className=" absolute ">
                  <img
                    className="sm:h-40  sm:w-[9rem] h-20 w-20 rounded-[6px]"
                    src={ImgPath + option?.image3}
                    alt=""
                  />
                </div>
              </div>
            </label>
            <label for={`radio-card-more`} className="grid radio-card">
              <input
                type="radio"
                name="radio-card"
                id={`radio-card-more`}
                onChange={handleOptionChange}
                checked={selectedOptionId === option?.id}
                value="Need more ideas"
              />
              <div className="card-content-wrapper relative sm:h-40  sm:w-[9rem] h-20 w-20" style={{ padding: "0px" }}>
                <span className="check-icon m-2 bg-white"></span>
                <div className=" absolute ">
                  <div className="sm:h-40  sm:w-40 h-20 w-20 rounded-[6px]" >
                    <div className="flex  items-center h-full	 text-center justify-center">
                    <h6>Need more ideas</h6>
                    </div>
                  </div>
                </div>
              </div>
            </label>


       
          </div>
        ))}
      </div>
      <div className="flex justify-center w-full mt-6">

<button className=" btn btn-primary" onClick={handleSubmit}>
                  Submit
                </button>
</div>
    </div>
  );
};

export default CheckboxImg;
